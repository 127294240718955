<template>
  <v-container fluid class="min-height">
    <v-row align="stretch" justify="center" class="d-flex align-stretch">
      <v-col
        cols="12"
        sm="12"
        v-if="!openForm"
        class="animate__animated animate__fadeInLeft animate__faster"
      >
        <v-card class="pb-4 fill-height">
          <v-card-title class="primary white--text mb-6">
            <v-icon class="mr-2 white--text">mdi-medical-bag</v-icon>
            Recetas actuales
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              fab
              dark
              color="success"
              @click="formularioCrear()"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-card-title>
          <div v-if="isLoading" class="pa-12">
            <v-row align="center" justify="center">
              <v-progress-circular
                :size="86"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-row>
            <v-row align="center" justify="center">
              <v-card-title class="primary--text">Cargando...</v-card-title>
            </v-row>
          </div>
          <div v-else>
            <v-row v-if="listaRecetas.length > 0" class="pa-6">
              <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="(item, index) in listaRecetas"
                :key="index"
              >
                <v-card
                  rounded
                  dark
                  color="accent darken-1"
                  class="animate__animated animate__fadeIn pa-4"
                  outlined
                  @click="formularioEditar(item)"
                >
                  <v-card-title>
                    <v-icon class="mr-1">mdi-pill</v-icon>
                    {{ item.name }}
                  </v-card-title>
                  <v-card-text>
                    <b>Comprimidos restantes:</b> <br />
                    {{ item.remainingPills }} <br />
                    <b>Inicio tratamiento:</b> <br />
                    {{ formatDate(item.startDate) }} <br />
                    <b>Fin tratamiento:</b> <br />
                    {{ formatDate(item.endDate) }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-card-text v-else>
              No se han encontrado recetas registradas.
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        v-if="openForm"
        class="animate__animated animate__fadeInLeft animate__faster"
      >
        <v-card class="pb-4 fill-height" v-if="openForm">
          <v-card-title class="success white--text mb-6">
            {{ isEdit ? "Actualizar receta" : "Nueva receta" }}
          </v-card-title>
          <v-form v-model="valid" class="pa-6">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Nombre del medicamento"
                  v-model="recipe.name"
                  :rules="genericRules"
                  required
                  outlined
                  rounded
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Total de pildoras"
                  type="number"
                  v-model="recipe.totalPills"
                  required
                  outlined
                  rounded
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Peso de comprimido en miligramos"
                  type="number"
                  v-model="recipe.pillsWeight"
                  required
                  outlined
                  rounded
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-dialog v-model="dialog" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-actions>
                      <v-card-text class="title">
                        <v-icon>mdi-alarm</v-icon>
                        Alarmas
                      </v-card-text>
                      <v-spacer />
                      <v-btn
                        color="success"
                        rounded
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Agregar alarma
                      </v-btn>
                    </v-card-actions>
                  </template>
                  <v-card>
                    <v-card-title class="headline">
                      Nueva alarma
                    </v-card-title>
                    <v-card-text>
                      <v-form v-model="validAlarm" class="pa-6">
                        <v-time-picker
                          :rules="genericRules"
                          required
                          v-if="dialog"
                          v-model="alarm"
                          format="24hr"
                          full-width
                        />
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn color="error" text @click="dialog = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="success"
                        rounded
                        :disabled="!validAlarm"
                        @click="agregarAlarma"
                        v-if="!isEditingAlarm"
                        >Agregar alarma</v-btn
                      >
                      <v-btn
                        color="success"
                        rounded
                        :disabled="!validAlarm"
                        v-if="isEditingAlarm"
                        @click="actualizarAlarma"
                        >Actualizar alarma</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-row v-if="recipe.alarms.length > 0" class="px-6">
                  <v-col
                    cols="12"
                    sm="3"
                    md="2"
                    v-for="(alarm, i) in recipe.alarms"
                    :key="i"
                  >
                    <v-chip
                      color="primary"
                      close
                      @click="editAlarm(alarm)"
                      v-if="alarm"
                      @click:close="removeAlarm(alarm)"
                    >
                      {{ formatTime(alarm.hour) }}:{{
                        formatTime(alarm.minute)
                      }}
                    </v-chip>
                  </v-col>
                </v-row>
                <v-card-text v-else>
                  <p>Hasta ahora no se han asociado alarmas a la receta</p>
                </v-card-text>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-card-text class="title"
                  >Fecha inicio de tratamiento</v-card-text
                >
                <v-date-picker
                  v-model="startDate"
                  full-width
                  color="accent"
                  required
                  :rules="genericRules"
                  header-color="primary"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-card-text class="title"
                  >Fecha termino de tratamiento</v-card-text
                >
                <v-date-picker
                  v-model="endDate"
                  full-width
                  required
                  :rules="genericRules"
                  color="accent"
                  header-color="primary"
                ></v-date-picker>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click="openForm = false">
                Cancelar
              </v-btn>
              <v-btn
                color="success"
                type="submit"
                rounded
                :disabled="!valid"
                v-if="!isEdit"
                @click="agregarReceta"
                >Agregar Receta</v-btn
              >
              <v-btn
                color="success"
                type="submit"
                rounded
                :disabled="!valid"
                v-if="isEdit"
                @click="actualizarReceta"
                >Actualizar Receta</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import dayjs from "dayjs";

export default {
  data: () => ({
    isLoading: true,
    recipe: {},
    dialog: false,
    valid: false,
    backupAlarm: null,
    isEditingAlarm: false,
    validAlarm: false,
    startDate: null,
    endDate: null,
    isEdit: false,
    alarm: "",
    emptyRecipe: {
      name: "",
      alarms: [],
      totalPills: 0,
      totalWeight: 0,
      pillsWeight: 0,
      startDate: "",
      endDate: "",
    },
    genericRules: [(v) => !!v || "El campo es requerido"],
    listaRecetas: [],
    snackbar: false,
    mensaje: "",
    openForm: false,
    indexTarea: "",
  }),
  watch: {
    endDate: function(value) {
      if (value < this.startDate) {
        this.endDate = "";
        this.valid = false;
        this.$toast.error(
          "La fecha de termino del tratamiento no puede ser menor a la fecha de inicio."
        );
      }
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAllRecipes();
  },
  computed: {},
  methods: {
    formatDate(value) {
      return new dayjs(value).format("DD/MM/YYYY");
    },
    formatTime(value) {
      return ("0" + value).slice(-2);
    },
    getAllRecipes() {
      this.isLoading = true;
      const userId = sessionStorage.getItem("userId");

      axios
        .get(`recipes/user/${userId}`)
        .then((response) => {
          const recipes = response.data.data;
          if (recipes?.length > 0) {
            this.listaRecetas = recipes;
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        })
        .catch((error) => {
          if (error) {
            this.$toast.error(
              "Ha ocurrido un error mientras se obtenian las recetas médicas."
            );
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        });
    },
    editAlarm(alarm) {
      this.alarm = `${alarm.hour}:${alarm.minute}`;
      this.backupAlarm = Object.assign({}, alarm);
      this.dialog = true;
      this.isEditingAlarm = true;
    },
    removeAlarm(item) {
      this.recipe.alarms = this.recipe.alarms.filter(
        (alarm) => alarm.hour !== item.hour && alarm.minute !== item.minute
      );
    },
    actualizarAlarma() {
      let data = this.alarm.split(":");
      this.recipe.alarms = this.recipe.alarms.map((alarm) => {
        if (
          alarm.hour === this.backupAlarm.hour &&
          alarm.minute === this.backupAlarm.minute
        ) {
          alarm.hour = data[0];
          alarm.minute = data[1];
        }
        return alarm;
      });
      this.alarm = "";
      this.backupAlarm = null;
      this.isEditingAlarm = false;
      this.dialog = false;
    },
    agregarAlarma() {
      let data = this.alarm.split(":");
      this.recipe.alarms.push({
        hour: data[0],
        minute: data[1],
      });
      this.alarm = "";
      this.dialog = false;
    },
    actualizarReceta() {
      if (this.startDate === "" || this.endDate === "") {
        this.$toast.error("Debe llenar los campos de fecha.");
      } else {
        this.recipe.startDate = this.startDate;
        this.recipe.endDate = this.endDate;
        this.recipe.userId = sessionStorage.getItem("userId");
        this.recipe.totalWeight =
          this.recipe.totalPills * this.recipe.pillsWeight;
        axios
          .put(`crud/recipes/${this.recipe.id}`, this.recipe)
          .then((response) => {
            if (response) {
              this.$toast.success("Actualizado con exito!");
              this.getAllRecipes();
            }
          })
          .catch((error) => {
            if (error) {
              this.$toast.error("Ha ocurrido un error.");
            }
          });
        this.openForm = false;
        this.isEdit = false;
        this.recipe = {};
      }
    },
    agregarReceta() {
      if (this.startDate === "" || this.endDate === "") {
        this.$toast.error("Debe llenar los campos de fecha.");
      } else {
        this.recipe.startDate = this.startDate;
        this.recipe.endDate = this.endDate;
        this.recipe.userId = sessionStorage.getItem("userId");
        this.recipe.totalWeight =
          this.recipe.totalPills * this.recipe.pillsWeight;
        axios
          .post(`crud/recipes`, this.recipe)
          .then((response) => {
            if (response) {
              this.$toast.success("Guardado con exito!");
              this.getAllRecipes();
            }
          })
          .catch((error) => {
            if (error) {
              this.$toast.error("Ha ocurrido un error.");
            }
          });
        this.openForm = false;
        this.recipe = {};
      }
    },
    formularioEditar(recipe) {
      this.recipe = Object.assign({}, recipe);
      this.startDate = new dayjs(this.recipe.startDate).format("YYYY-MM-DD");
      this.endDate = new dayjs(this.recipe.endDate).format("YYYY-MM-DD");
      this.openForm = true;
      this.isEdit = true;
    },
    formularioCrear() {
      this.recipe = Object.assign({}, this.emptyRecipe);
      this.startDate = new dayjs().format("YYYY-MM-DD");
      this.endDate = new dayjs().format("YYYY-MM-DD");
      this.openForm = true;
    },
  },
};
</script>
